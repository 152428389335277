<template>
  <div>
    <el-form ref="form"
             :model="request"
             label-width="80px">
      <el-form-item label="Title">
        <el-input v-model="request.title"></el-input>
      </el-form-item>
      <el-form-item label="Description">
        <el-input type="textarea"
                  :rows="30"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      request: {
        title: '',
        description: ''
      }
    }
  },
  mounted() {}
}
</script>
<style scoped>
</style>